import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/radca/#nadcha"> &gt; Radca(NÁDCHA)</a>
            <a href="/radca/nadcha/spoznajte_typy_nadchy/" className="sel">
              {" "}
              &gt; Spoznajte typy nádchy
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/artykuly/shutterstock_388715146.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Spoznajte typy nádchy</h1>
              <div className="wysiwyg">
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <b>
                    Nádcha sa prejavuje nadmernou tvorbou hlienov v nose. Tento
                    stav má niekoľko príčin: zápal nosovej sliznice sa môže
                    objaviť spolu s prechladnutím a chrípkou alebo po kontakte s{" "}
                    <a href="/slovnik_pojmov/#alergen" target="_blank">
                      alergénmi
                    </a>
                    &nbsp;či dráždivými látkami. Vzhľadom na to rozlišujeme
                    vírusovú nádchu, bakteriálnu nádchu,{" "}
                    <a href="/slovnik_pojmov/#aergicka_nadcha">
                      alergickú nádchu
                    </a>
                    &nbsp;a vazomotorickú nádchu.{" "}
                  </b>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Nádcha, ktorá sa objavuje počas sezóny prechladnutí, býva
                  zväčša vyvolaná vírusovou{" "}
                  <a href="/slovnik_pojmov/#infekcia" target="_blank">
                    infekciou
                  </a>
                  . Práve tento typ nádchy nás najčastejšie trápi na jeseň a na
                  jar. Oslabenie imunity nasleduje v dôsledku kontaktu so
                  škodlivými mikroorganizmami, napríklad aj rinovírusmi (vírusmi
                  vyvolávajúcimi zápal nosovej sliznice).{" "}
                  <a href="/slovnik_pojmov/#patogen" target="_blank">
                    Patogénne
                  </a>
                  &nbsp;mikroorganizmy sa prenášajú kvapôčkami vo vzduchu
                  obvykle vtedy, ak sme v jednej miestnosti s kýchajúcim alebo
                  kašľajúcim človekom. Keď preniknú do nášho organizmu, začnú sa
                  množiť a vyvolajú zápal. V prípade zápalu nosovej sliznice sa
                  jej cievy nadmerne naplnia krvou, rozšíria sa a nosová
                  sliznica opuchne. Ide o správnu obrannú reakciu tela. Začneme
                  pociťovať problémy s dýchaním nosom a veľmi často nás trápi aj
                  bolesť hlavy. Výtok tvoriaci sa v nose je spočiatku vodnatý
                  (súvisí to s prekrvením ciev v nose), ale v priebehu choroby
                  hustne.
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Bakteriálna nádcha sa objavuje v dôsledku bakteriálnej nákazy
                  nosovej sliznice poškodenej vírusmi. Prítomnosť hnisu a
                  hlienov v nosových kanálikoch spôsobí, že výtok zmení farbu –
                  z bezfarebného na žltkastý alebo zelenkavý.
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <img
                    alt="OLYNTH® riešenia pre rôzne typy nádchy."
                    src="/web/kcfinder/uploads/images/shutterstock_272161175.jpg"
                    style={{
                      width: "380px",
                      height: "200px",
                      "border-width": "2px",
                      "border-style": "solid",
                      float: "right",
                      margin: "5px 0px 10px 10px"
                    }}
                  />
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <a href="/slovnik_pojmov/#aergicka_nadcha" target="_blank">
                    Alergická nádcha
                  </a>
                  &nbsp;(označovaná ako senná nádcha) nie je nákazlivá. V
                  prípade stáleho kontaktu s{" "}
                  <a href="/slovnik_pojmov/#alergen" target="_blank">
                    alergénom
                  </a>
                  &nbsp;môže byť chronická, sezónna alebo celoročná. U
                  niektorých ľudí sa prejaví pri kontakte s roztočmi v prachu z
                  domácnosti, so zvieracou srsťou alebo dymom. Ďalších trápi{" "}
                  <a href="/slovnik_pojmov/#aergicka_nadcha">
                    alergická nádcha
                  </a>
                  , keď začnú kvitnúť rastliny alebo stromy. U alergikov vzniká
                  vodnatý, bezfarebný, pomerne rozsiahly výtok, ktorý nemení
                  svoju konzistenciu. Pre alergiu je charakteristický aj zápal
                  spojoviek, začervenanie očí, slzenie, poruchy spánku a
                  koncentrácie, ale zvýšená teplota sa vyskytuje pomerne
                  zriedka. Pri alergii veľmi často užívame antihistaminiká.
                  Nesmieme však zabúdať, že síce zmierňujú svrbenie a zastavujú
                  výtok z nosa, ale nos neodblokujú. Z tohto dôvodu je
                  nevyhnutné použiť lieky, ktoré dodatočne spriechodnia nos
                  (napr. lieky OLYNTH<sup>®</sup>).
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Vazomotorická nádcha nie je dôsledkom infekcie ani
                  precitlivenosti. Môže ju spôsobovať prudká zmena teploty
                  (napr. po príchode zvonka do vyhriateho priestoru ako
                  napríklad do autobusu), pikantné jedlo, vykonávanie telesnej
                  aktivity, vystavenie sa silnému svetlu alebo zmena polohy zo
                  zvislej na vodorovnú.
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  S pocitom upchatého nosa možno bojovať používaním príslušných liekov.
                  Pri nádche často pociťujeme sucho v nose. Je preto vhodné siahnuť po
                  liekoch, ktoré spôsobujú nielen uvoľnenie dýchania nosom, ale aj  
                  zvlhčenie nosovej sliznice. Dobrým riešením je použitie lieku OLYNTH® HA 0,1 %, 
                  pretože zvlhčuje nosovú sliznicu vďaka trom zvlhčujúcim látkam.{" "}
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    Literatúra:
                  </span>
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    A. Emeryk, P. Rapiejko, A. Lipiec,{" "}
                    <em>Alergiczny nieżyt nosa</em>, Poznań 2013.{" "}
                  </span>
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    P. Rapiejko, A. Lipiec, <em>Podział nieżytów nosa</em>, [v:]
                    „Alergia”, 2013, nr 2, s. 6-8.{" "}
                  </span>
                </p>
              </div>
              <div className="other_article">
                <p className="name">Ďalšie články</p>
                <a
                  href="/radca/nadcha/ako_si_spravne_cistit_nos/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/artykuly/shutterstock_300834140.jpg)"}} />
                  <span className="title">
                    Ako si správne čistiť nos pri nádche?
                  </span>
                </a>
                <a
                  href="/radca/nadcha/zabojujte_s_nadchou_rychlo/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/shutterstock_152527238.jpg)"}} />
                  <span className="title">Zabojujte s nádchou rýchlo</span>
                </a>
                <a
                  href="/radca/nadcha/alergicka_nadcha_spoznajte_ju_a_predchadzajte_jej/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/shutterstock_1206612040.jpg)"}} />
                  <span className="title">
                    Alergická nádcha – spoznajte ju a predchádzajte jej
                  </span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <Footer></Footer>{" "}
        
      </div>
    );
  }
}

export default Page;
